@tailwind base;
@tailwind components;
@tailwind utilities;

@layer tailwind-base, primereact, tailwind-utilities;

@layer tailwind-base {
  @tailwind base;
}

@layer tailwind-utilities {
  @tailwind components;
  @tailwind utilities;
}

@layer components {
  .page-content {
    @apply container mx-auto px-6 py-6 sm:py-12;
  }

  .btn {
    @apply flex cursor-pointer items-center justify-center rounded-md px-8 py-3 transition-all;
  }

  .btn--primary {
    @apply btn bg-primary text-white hover:bg-primaryDark active:bg-primaryDarker;
  }

  .btn--primary-outlined {
    @apply btn border border-primary bg-white text-primary hover:bg-primary hover:text-white active:bg-primaryDark;
  }

  .btn--secondary {
    @apply btn bg-secondary text-black hover:bg-secondaryDark active:bg-secondaryDarker;
  }

  .btn--secondary-outlined {
    @apply btn border border-secondary text-secondary hover:bg-secondary hover:text-white active:bg-secondaryDark;
  }

  .btn--orange {
    @apply btn bg-orange text-white hover:bg-orangeDark active:bg-orangeDarker;
  }

  .btn--orange-outlined {
    @apply btn border border-orange text-orange hover:bg-orange hover:text-white active:bg-orangeDark;
  }

  .btn--white {
    @apply btn border border-gray bg-white text-black hover:bg-zinc-100 active:bg-zinc-200;
  }

  .btn--black {
    @apply btn bg-black text-white hover:bg-slate-900 active:bg-slate-950;
  }

  .btn--black-outlined {
    @apply btn border border-black bg-white text-black;
  }

  .btn--danger {
    @apply btn bg-red-500 text-white hover:bg-red-600 active:bg-red-700;
  }

  .input {
    @apply rounded-md border border-gray bg-white shadow-none;
  }

  /* typography */

  .text--huge {
    @apply font-din text-h1 font-bold uppercase leading-[3.75rem] sm:text-huge sm:leading-[6.75rem];
  }

  .text--h1 {
    @apply font-din text-h1sm font-bold uppercase leading-[3.75rem]  sm:text-h1;
  }

  .text--h2 {
    @apply font-din text-h2 font-bold uppercase  leading-9;
  }

  .text--h3 {
    @apply font-din text-h3 font-bold uppercase  leading-[1.875rem];
  }

  .text--h4 {
    @apply font-din text-h4 uppercase leading-6;
  }

  .text--largeP {
    @apply font-geomanist text-largeP leading-[1.875rem];
  }

  .text--paragraph {
    @apply font-geomanist text-paragraph;
  }

  .text--smallP {
    @apply font-geomanist text-smallP leading-[1.3125rem];
  }

  .text--xSmallP {
    @apply font-geomanist text-xSmallP;
  }
}
