*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
}

input,
textarea {
  outline: none;
}

html {
  @apply text-[93%] sm:text-[100%];
}

body {
  font-family: sans-serif;
  @apply flex h-full min-h-screen flex-col text-paragraph text-black;
}

#root {
  @apply flex h-full min-h-screen flex-col;
}
