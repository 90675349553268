/* fix for leaflet */
.leaflet-control {
  z-index: 0 !important;
}
.leaflet-pane {
  z-index: 0 !important;
}
.leaflet-top,
.leaflet-bottom {
  z-index: 0 !important;
}

/* fix for leaflet popup */

.bar-popup .leaflet-popup {
  @apply !mb-4;
}

.bar-popup .leaflet-popup-content-wrapper {
  @apply !rounded-2xl !p-0 px-0;
}

.bar-popup .leaflet-popup-content {
  @apply !m-0;
}

.leaflet-popup-content p {
  @apply !m-0;
}

.bar-popup .leaflet-popup-close-button {
  @apply hidden;
}

.bar-popup .leaflet-popup-tip-container {
  @apply hidden;
}

/* custom scroll-bar */

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #7c9294;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #687678;
}

/* toast styles */

/* Toast classes */

.colored-toast.swal2-icon-success {
  background-color: #5cea0a !important;
}

.colored-toast.swal2-icon-error {
  background-color: #f27474 !important;
}

.colored-toast.swal2-icon-warning {
  background-color: #f8bb86 !important;
}

.colored-toast.swal2-icon-info {
  background-color: #3fc3ee !important;
}

.colored-toast.swal2-icon-question {
  background-color: #87adbd !important;
}

.colored-toast .swal2-title {
  color: white;
}

.colored-toast .swal2-close {
  color: white;
}

.colored-toast .swal2-html-container {
  color: white;
}

/* react date range mobile fix */

@media (max-width: 480px) {
  .rdrCalendarWrapper {
    width: 100% !important;
  }

  .rdrMonth {
    width: 100% !important;
  }
}
