/* DIN CONDENSED */
@font-face {
  font-family: "DIN Condensed";
  src: url("../fonts/din-condensed/D-DINCondensed.otf") format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: "DIN Condensed";
  src: url("../fonts/din-condensed/D-DINCondensed-Bold.otf") format("opentype");
  font-weight: 700;
}

/* Geomanist */

@font-face {
  font-family: "Geomanist";
  src: url("../fonts/geomanist/Geomanist-Regular.otf") format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: "Geomanist";
  src: url("../fonts/geomanist/Geomanist-Regular-Italic.otf") format("opentype");
  font-weight: 400;
  font-style: italic;
}
